import React from 'react';
import {getMixLottoList, getQuickMixLottoList} from '../result-service';
import {MixLottoListItem} from '../result.type';
import {View, ScrollView} from 'react-native';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import LazyImage from '@/components/basic/image';
import Text from '@/components/basic/text';
import dayjs from 'dayjs';
import {DownIcon} from '@/pages/casino-old/svg.variable';
import {useTranslation} from 'react-i18next';
import {goTo} from '@/utils';
const StateLottery = ({isActive}: {isActive?: boolean}) => {
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState<MixLottoListItem[]>([]);
  const [quick, setQuick] = React.useState<MixLottoListItem[]>([]);
  React.useEffect(() => {
    if (isActive) {
      setLoading(true);
      getQuickMixLottoList().then(_list => {
        setQuick(_list || []);
      });
      getMixLottoList()
        .then(_list => {
          setList(_list || []);
        })
        .finally(() => setLoading(false));
    }
  }, [isActive]);

  const {t} = useTranslation();

  const target = React.useMemo(() => {
    return quick.concat(list);
  }, [quick, list]);

  return (
    <Spin
      loading={loading}
      style={[theme.flex.flex1, theme.background.lightGrey]}>
      <ScrollView
        contentContainerStyle={[theme.padding.l, {gap: theme.paddingSize.m}]}>
        {target.map((item, _i) => {
          return (
            <NativeTouchableOpacity
              key={_i}
              onPress={() => {
                goTo('MixLotteryDetail', {
                  name: item.lotteryName,
                  lotteryID: item.lotteryID,
                  isQuick: item.isQuick ? '1' : '0',
                  filterList: JSON.stringify(target),
                });
              }}
              style={[
                theme.background.white,
                theme.flex.row,
                theme.borderRadius.m,
                theme.padding.l,
                theme.flex.centerByCol,
              ]}>
              <View
                style={[
                  theme.flex.flex1,
                  theme.flex.row,
                  theme.flex.centerByCol,
                ]}>
                <LazyImage
                  imageUrl={item.icon}
                  width={40}
                  height={40}
                  occupancy="transparent"
                />
                <View
                  style={[theme.margin.lefts, {gap: theme.paddingSize.xxs}]}>
                  <Text fontFamily="fontInterBold" size="medium" main>
                    {item.lotteryName}
                  </Text>
                  {!item.isQuick && (
                    <Text
                      accent
                      style={[
                        // eslint-disable-next-line react-native/no-inline-styles
                        {
                          fontWeight: '500',
                        },
                      ]}>
                      {t('result-page.label.latestDrawDate')}
                      {': '}
                      {item.drawTime && item.drawTime > 0
                        ? dayjs(item.drawTime * 1000).format('DD-MM-YYYY hh:mm')
                        : '-'}
                    </Text>
                  )}
                </View>
              </View>
              <View
                style={[
                  {
                    transform: [{rotate: '-90deg'}],
                  },
                ]}>
                <DownIcon />
              </View>
            </NativeTouchableOpacity>
          );
        })}
      </ScrollView>
    </Spin>
  );
};

export default StateLottery;
