import React from 'react';
import {useFocusEffect} from '@react-navigation/native';

const SportsContainer = () => {
  useFocusEffect(() => {
    setTimeout(() => {
      if (window.location.pathname.includes('/index/sports')) {
        const gr8 = document.getElementById('gr8');
        if (gr8) {
          gr8.style.display = 'flex';
        }
      }
    }, 1000);
    return () => {
      const gr8 = document.getElementById('gr8');
      if (gr8) {
        gr8.style.display = 'none';
      }
    };
  });
  return <></>;
};

export default SportsContainer;
